<template>
  <CartSummary :items="items" :count="count" />
</template>

<script setup lang="ts">
defineComponent({
  name: 'CheckoutCartSummary',
});

const { items, count } = useCartAttributes();
</script>

<i18n>
{
  "en": {
    "products": "Products",
    "qty": "Qty"
  },
  "ar": {
    "products": "المنتجات",
    "qty": "الكمية"
  }
}
</i18n>
