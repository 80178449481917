import { State } from './../types/address';
import * as yup from 'yup';
import { CustomerAddress } from '@robustastudio/e-commerce/dist/runtime/graphql/graphql-types.gen';
import { ShippingMethod } from '@robustastudio/e-commerce/dist/runtime/composables/cart/place-order';
import { AvailablePaymentMethod } from '@robustastudio/e-commerce/dist/runtime/graphql/Address';

function merge(...schemas: any[]) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
}

type ObjectInputType = { id: number; code: string; name: string };
export type FormValuesType = {
  fullName?: string;
  email?: string;
  phone?: string;
  address?: string;
  region?: ObjectInputType;
  city?: string;
  floor?: string;
  landmark?: string;
  street?: string;
  title?: string;
  apartment?: string;
  selectedAddress?: CustomerAddress;
  customerEmail?: string;
  password?: string;
  customerPhone?: string;
  customerFullName?: string;
};
export function useCheckoutShippingValidationSchema(addressPickerState: Ref<State | undefined>) {
  const { isLoggedIn } = useAuth();
  const { t } = useI18n({
    useScope: 'global',
  });

  const addressValidationSchema = yup.object({
    fullName: yup
      .string()
      .required(t('validation.required').toString())
      .test('fullName', t('validation.fullName').toString(), function (value: string) {
        return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
      }),
    phone: yup
      .mixed()
      .required(t('validation.required').toString())
      .test('phone', t('validation.invalidPhone').toString(), function (value) {
        return isPhone(value.toString());
      }),
    region: yup.object().required(t('validation.region').toString()),
    city: yup.string().required(t('validation.city').toString()),
    street: yup.string().required(t('validation.street').toString()),
    floor: yup.string().optional(),
    apt: yup.string().optional(),
    landmark: yup.string().optional(),
  });

  const createCustomerValidationSchema = yup.object({
    customerFullName: yup
      .string()
      .required(t('validation.required').toString())
      .test('customerFullName', t('validation.fullName').toString(), function (value: string) {
        return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
      }),
    customerEmail: yup
      .string()
      .email(t('validation.email'))
      .required(t('validation.required').toString())
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, t('validation.email')),
    customerPhone: yup
      .mixed()
      .required(t('validation.required').toString())
      .test('customerPhone', t('validation.invalidPhone').toString(), function (value) {
        return isPhone(value.toString());
      }),
    password: yup.string().required(t('validation.required')).min(8, t('validation.password')),
  });

  const selectedAddressValidationSchema = yup.object({ selectedAddress: yup.object().required() });

  const validationSchema = computed(() => {
    if (!isLoggedIn.value) {
      // is a guest customer
      return merge(createCustomerValidationSchema, addressValidationSchema);
    }
    // is a logged in customer
    if (addressPickerState.value === 'IS_ADDING') {
      return merge(addressValidationSchema);
    }

    if (addressPickerState.value === 'IS_UPDATING') {
      return merge(selectedAddressValidationSchema, addressValidationSchema);
    }

    return selectedAddressValidationSchema;
  });

  return {
    validationSchema,
  };
}

export function useSetAutoShippingMethod() {
  const { setShippingMethod, isFetching } = useShippingMethods();
  const { value: selectedShippingMethod } = useField<ShippingMethod>('shippingMethod');

  watch(selectedShippingMethod, shippingMethod => {
    if (shippingMethod) {
      setShippingMethod({
        carrier_code: shippingMethod.carrier_code,
        method_code: shippingMethod.method_code || '',
      });
    }
  });

  return {
    isSettingShippingMethod: computed(() => isFetching.value),
  };
}

export function useSetAutoPaymentMethod() {
  const { setPaymentMethod, isFetching } = usePaymentMethods();
  const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');

  watch(selectedPaymentMethod, paymentMethod => {
    if (paymentMethod) {
      setPaymentMethod(paymentMethod.code);
    }
  });

  return {
    isSettingPaymentMethod: computed(() => isFetching.value),
  };
}

export function useGuestCheckoutShippingValidationSchema() {
  const { t } = useI18n({
    useScope: 'global',
  });

  const addressValidationSchema = toTypedSchema(
    yup.object({
      fullName: yup
        .string()
        .required(t('validation.required').toString())
        .test('fullName', t('validation.fullName').toString(), function (value: string) {
          return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
        }),
      customerFullName: yup
        .string()
        .required(t('validation.required').toString())
        .test('customerFullName', t('validation.fullName').toString(), function (value: string) {
          return !!value.trim().match(/^([^\s\d]+)\s+(\D+)$/g);
        }),
      customerPhone: yup
        .string()
        .required(t('validation.required').toString())
        .test('customerPhone', t('validation.invalidPhone').toString(), function (value) {
          return isPhone(value.toString());
        }),
      phone: yup
        .string()
        .required(t('validation.required').toString())
        .test('phone', t('validation.invalidPhone').toString(), function (value) {
          return isPhone(value.toString());
        }),
      region: yup
        .object({
          name: yup.string().required(),
          code: yup.string().required(),
          id: yup.number().required(),
        })
        .required(t('validation.region').toString()),
      city: yup.string().required(t('validation.required').toString()),
      street: yup.string().required(t('validation.required').toString()),
      floor: yup.string().optional(),
      apartment: yup.string().optional(),
      landmark: yup.string().optional(),
      email: yup
        .string()
        .email(t('validation.email'))
        .required(t('validation.required').toString())
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, t('validation.email')),
    })
  );

  return { addressValidationSchema };
}

export function useAutoFillingAddress() {
  const { value: customerFullName } = useField('customerFullName');

  const { value: customerPhone } = useField<string>('customerPhone');

  const { setValue: setFullName } = useField('fullName');
  const { setValue: setPhoneNumber } = useField('phone');

  watch(customerPhone, async newCustomerPhone => {
    setPhoneNumber(newCustomerPhone);
  });

  watch(customerFullName, async newCustomerFullName => {
    setFullName(newCustomerFullName);
  });
}
